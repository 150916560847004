export default class SwitchText {
  constructor($container) {
    this.$container = $container;
    this.puceWidth = 24 + 16;
  }

  init() {
    let labelMaxWidth = 0;

    this.$container.querySelectorAll('label').forEach(($label) => {
      const labelWidth = $label.offsetWidth;
      if (labelWidth > labelMaxWidth) {
        labelMaxWidth = labelWidth;
      }
    });

    this.$container.style.width = `${labelMaxWidth + this.puceWidth}px`;
    this.$container.classList.add('load');
  }
}

window.SwitchText = SwitchText;
