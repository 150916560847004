export default class BoxUtility {
  constructor($container) {
    this.$container = $container;
  }

  init() {
    this.$choices = this.$container.querySelectorAll('.gl_boxUtility:not(.disabled)');
    this.$choices.forEach(($boxUtility) => {
      $boxUtility.addEventListener('click', () => {
        this.unselect();
        $boxUtility.classList.add('active');
      });
    });
  }

  unselect() {
    this.$choices.forEach(($boxUtility) => {
      $boxUtility.classList.remove('active');
    });
  }
}

window.BoxUtility = BoxUtility;
