import Prism from 'prismjs';
import 'prismjs/themes/prism-tomorrow.css';
import './component/polyfills';

import Box from './component/Box';
import SwitchText from './component/SwitchText';
import BoxUtility from './component/BoxUtility';

document.addEventListener('DOMContentLoaded', () => {
  // Box
  document.querySelectorAll('.gl_box_container').forEach(($box) => {
    const box = new Box($box, ($element) => {
      $element.querySelectorAll('.gl_switch-text').forEach(($switch) => {
        const switchText = new SwitchText($switch);
        switchText.init();
      });

      // BoxUtility
      $element.querySelectorAll('.gl_boxUtility_container').forEach(($boxUtilityContainer) => {
        const boxUtility = new BoxUtility($boxUtilityContainer);
        boxUtility.init();
      });
    });
    box.init();
  });

  // SwitchText
  document.querySelectorAll('.gl_switch-text').forEach(($switch) => {
    const switchText = new SwitchText($switch);
    switchText.init();
  });

  // BoxUtility
  document.querySelectorAll('.gl_boxUtility_container').forEach(($boxUtilityContainer) => {
    const boxUtility = new BoxUtility($boxUtilityContainer);
    boxUtility.init();
  });

  Prism.highlightAll();
});
