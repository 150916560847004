function showElement($element, ini = false) {
  const element = $element;
  if (!ini) {
    element.style.maxHeight = `${0}px`;
  }
  setTimeout(() => {
    element.style.maxHeight = `${element.querySelector('.gl_box_toggle_content').offsetHeight}px`;
    setTimeout(() => {
      element.style.maxHeight = '';
    }, 300);
  }, ini ? 0 : 1);
}

function hideElement($element, ini = false) {
  const element = $element;
  if (!ini) {
    element.style.maxHeight = `${element.querySelector('.gl_box_toggle_content').offsetHeight}px`;
  }
  setTimeout(() => {
    element.style.maxHeight = `${0}px`;
  }, ini ? 0 : 1);
}

function initToggleBox($box) {
  if ($box.classList.contains('gl_box_toggle')) {
    const $boxDescribeToggle = $box.querySelector('.gl_boxDescribe_toggle');
    const $boxDescribe = $box.querySelector('.gl_boxDescribe');
    const $boxToggleAfter = $box.querySelector('.gl_box_toggleAfter');

    if ($boxDescribeToggle) {
      hideElement($boxDescribeToggle, true);
      showElement($boxDescribe, true);
    }
    if ($boxToggleAfter) {
      hideElement($boxToggleAfter, true);
    }
  }
}
function replaceContent(content, location) {
  if (typeof content === 'object' && typeof location === 'object') {
    const contentToMove = [];

    /* eslint-disable no-param-reassign */
    location.innerHTML = '';
    /* eslint-enable no-param-reassign */

    Object.keys(content).forEach((key) => {
      if (Object.prototype.hasOwnProperty.call(content, key)) {
        contentToMove.push(content[key]);
      }
    });

    for (let i = 0; i < contentToMove.length; i += 1) {
      location.append(contentToMove[i]);
    }
  }
}

export default class Box {
  constructor($container, callbackFunction = null) {
    this.$container = $container;
    this.$choices = [];
    this.isToggleBlock = false;
    this.callbackFunction = callbackFunction;
    this.choicesContent = [];
  }

  init() {
    this.$choices = this.$container.querySelectorAll('.gl_box:not(.disabled):not(.gl_boxUtility)');

    // check if toggle block
    if (this.$container.classList.contains('gl_box_container-toggle')) {
      this.isToggleBlock = true;
      // creat anime box overlay
      this.sideBoxMobile = document.createElement('div');
      this.sideBoxMobile.classList.add('gl_box_toggleMobile');
      const sideBoxMobileHtml = '<div class="gl_box_toggleMobile_close">'
                              + '<span class="gl-i-arrow-left"></span>'
                              + '</div>'
                              + '<div class="gl_box_toggleMobile_container"></div>';

      this.sideBoxMobile.innerHTML = sideBoxMobileHtml;
      document.body.appendChild(this.sideBoxMobile);

      this.sideBoxMobile.querySelector('.gl_box_toggleMobile_close').addEventListener('click', () => {
        let boxIndexClose = null;

        this.$choices.forEach(($box, boxIndex) => {
          const $boxChecked = $box.querySelector('.gl_box_input input');

          if ($boxChecked) {
            $boxChecked.checked = false;
          } else {
            boxIndexClose = boxIndex;
          }
        });

        this.removeAnimation(boxIndexClose);
        this.sideBoxMobile.classList.remove('open');
        setTimeout(() => {
          this.sideBoxMobile.classList.remove('show');
          document.querySelector('body').classList.remove('staticBoxOpen');
        }, 300);
      });

      this.$choices.forEach(($box) => {
        this.choicesContent.push($box);
      });
    }

    this.$choices.forEach(($box, boxIndex) => {
      // Close toggle or init height
      initToggleBox($box);

      const $radio = $box.querySelector('input[type="radio"]');

      $box.addEventListener('click', () => {
        if (!$box.classList.contains('animPulse')) {
          this.addAnimation($box, boxIndex);
          if ($radio) {
            $radio.click();
          }
        }
      });

      if ($radio && $radio.checked && window.innerWidth > 767) {
        this.addAnimation($box);
      } else if ($radio && $radio.checked) {
        $radio.checked = false;
      }
    });
    if (this.$choices.length === 1 && window.innerWidth > 767) {
      this.addAnimation(this.$choices[0]);

      const $radio = this.$choices[0].querySelector('input[type="radio"]');
      if ($radio) {
        $radio.click();
      }
    }
  }

  addAnimation($element, contentIndex = null) {
    this.removeAnimation();
    $element.classList.add('animPulse');
    if (window.innerWidth > 767) {
      // open toggle content
      const $boxDescribeToggle = $element.querySelector('.gl_boxDescribe_toggle');
      const $boxDescribe = $element.querySelector('.gl_boxDescribe');
      const $boxToggleAfter = $element.querySelector('.gl_box_toggleAfter');

      if ($boxDescribeToggle) {
        showElement($boxDescribeToggle);
        hideElement($boxDescribe);
      }
      if ($boxToggleAfter) {
        showElement($boxToggleAfter);
      }
    } else if (this.isToggleBlock) {
      document.querySelector('body').classList.add('staticBoxOpen');
      const $sideBoxMobileContainer = this.sideBoxMobile.querySelector('.gl_box_toggleMobile_container');
      if (contentIndex) {
        replaceContent(this.choicesContent[contentIndex].children, $sideBoxMobileContainer);
      } else {
        $sideBoxMobileContainer.innerHTML = $element.innerHTML;
      }

      if (this.callbackFunction) {
        this.callbackFunction($sideBoxMobileContainer);
      }

      // Box
      $sideBoxMobileContainer.querySelectorAll('.gl_box_container').forEach(($box) => {
        const box = new Box($box);
        box.init();
      });

      this.sideBoxMobile.classList.add('show');
      setTimeout(() => {
        this.sideBoxMobile.classList.add('open');
      }, 1);
    }
  }

  removeAnimation(contentIndex = null) {
    this.$choices.forEach(($box) => {
      if ($box.classList.contains('animPulse')) {
        $box.classList.remove('animPulse');

        if (window.innerWidth > 767) {
          // close toggle content
          const $boxDescribeToggle = $box.querySelector('.gl_boxDescribe_toggle');
          const $boxDescribe = $box.querySelector('.gl_boxDescribe');
          const $boxToggleAfter = $box.querySelector('.gl_box_toggleAfter');

          if ($boxDescribeToggle) {
            hideElement($boxDescribeToggle);
            showElement($boxDescribe);
          }
          if ($boxToggleAfter) {
            hideElement($boxToggleAfter);
          }
        } else if (this.isToggleBlock && contentIndex) {
          const $sideBoxMobileContainer = this.sideBoxMobile.querySelector('.gl_box_toggleMobile_container');
          replaceContent($sideBoxMobileContainer.children, this.choicesContent[contentIndex]);
        }
      }
    });
  }
}

window.Box = Box;
